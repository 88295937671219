import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  SubTitle,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Specific Heat Calculator",
    link: "/specific-heat-calculator/",
  },
]
const seeAlsoArray = [
  "/boyles-law-calculator",
  "/charles-law-calculator",
  "/ohms-law-calculator",
  "/specific-heat-calculator",
  "/density-calculator",
]

function SpecificHeatCalculator(props) {
  let [myError, setmyError] = useState("")

  let [energy, setenergy] = useState("")
  let [mass, setmass] = useState("")
  let [temperature1, settemperature1] = useState("")
  let [temperature2, settemperature2] = useState("")
  let [specificHeat, setspecificHeat] = useState("")

  function energyC(e) {
    setenergy(e.target.value)
  }
  function massC(e) {
    setmass(e.target.value)
  }
  function temperature1C(e) {
    settemperature1(e.target.value)
  }
  function temperature2C(e) {
    settemperature2(e.target.value)
  }
  function specificHeatC(e) {
    setspecificHeat(e.target.value)
  }

  // var mydiv = document.getElementById('myerror');
  var errors = function (
    energy,
    mass,
    temperature1,
    temperature2,
    specificHeat
  ) {
    var inputs = [
      "energy",
      "mass",
      "temperatureInitial",
      "temperatureFinal",
      "specificHeat",
    ]
    var blankInputs = []
    for (let i = 0; i < 5; i++) {
      var number
      switch (inputs[i]) {
        case "energy":
          number = energy
          break
        case "mass":
          number = mass
          break
        case "temperatureInitial":
          number = temperature1
          break
        case "temperatureFinal":
          number = temperature2
          break
        case "specificHeat":
          number = specificHeat
          break
        default:
          console.log("default")
      }

      if (number === "") {
        blankInputs.push(inputs[i])
      }
    }

    if (blankInputs.length === 2) {
      setmyError(
        <strong>
          Your {blankInputs[0]} and {blankInputs[1]} input value are blank! We
          need four input value to find the fifth!
        </strong>
      )
    } else if (blankInputs.length === 3) {
      setmyError(
        <strong>
          Your {blankInputs[0]}, {blankInputs[1]}, and {blankInputs[2]} input
          value are blank! We need four input value to find the fifth!
        </strong>
      )
    } else if (blankInputs.length === 4) {
      setmyError(
        <strong>
          Your {blankInputs[0]}, {blankInputs[1]}, {blankInputs[2]}, and{" "}
          {blankInputs[3]} input value are blank! We need four input value to
          find the fifth!
        </strong>
      )
    } else if (blankInputs.length === 5) {
      setmyError(
        <strong>
          All of your inputs are blank! We need four input value to find the
          fifth!
        </strong>
      )
    } else if (blankInputs.length === 0) {
      setmyError(
        <strong>
          All of your input value are filled! We only need four input value to
          find the fifth!
        </strong>
      )
    } else if (temperature1 === temperature2) {
      setmyError(
        <strong>
          Your two temperatures are the same! If the substance has no change in
          temperature, no heat is gained or lost!
        </strong>
      )
    } else {
      return true
    }
  }

  var emtts = function (
    energy,
    mass,
    temperature1,
    temperature2,
    specificHeat
  ) {
    if (energy === "") {
      return "energy"
    } else if (mass === "") {
      return "mass"
    } else if (temperature1 === "") {
      return "temperature1"
    } else if (temperature2 === "") {
      return "temperature2"
    } else if (specificHeat === "") {
      return "specificHeat"
    }
  }

  var calculation = function (
    solving,
    energy,
    mass,
    temperature1,
    temperature2,
    specificHeat
  ) {
    switch (solving) {
      case "energy":
        setenergy(specificHeat * mass * (temperature2 - temperature1))
        setmyError("")
        break
      case "mass":
        setmass(energy / (specificHeat * Math.abs(temperature2 - temperature1)))
        setmyError("")
        break
      case "temperature1":
        settemperature1(-(energy / (specificHeat * mass)) - temperature2)
        setmyError("")
        break
      case "temperature2":
        settemperature2(energy / (specificHeat * mass) + temperature1)
        setmyError("")
        break
      case "specificHeat":
        setspecificHeat(energy / (mass * Math.abs(temperature2 - temperature1)))
        setmyError("")
        break
      default:
        console.log("default")
    }
  }

  var reset = function () {
    setenergy("")
    setmass("")
    settemperature1("")
    settemperature2("")
    setspecificHeat("")
    setmyError("")
  }

  var calculate = function () {
    if (errors(energy, mass, temperature1, temperature2, specificHeat)) {
      var solving = emtts(
        energy,
        mass,
        temperature1,
        temperature2,
        specificHeat
      )
      calculation(
        solving,
        energy,
        mass,
        temperature1,
        temperature2,
        specificHeat
      )
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Specific Heat Calculator"
        description="Specific Heat Calculator, Heat is the amount of energy streaming from one body of matter to another automatically due to their temperature variation. The specific heat capacity (specific heat), is the heat capacity per unit mass of material."
        keywords={[
          "Specific Heat, Specific Heat calculator, calculate Temperature initial, Temperature final, specific heat, mass, specific heat volume, heat calculation.",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Specific Heat Calculator</Title>
        <SubTitle>Specific Heat</SubTitle>
        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Energy</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  value={energy}
                  onChange={energyC}
                />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  J
                </StaticButton>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Mass</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" value={mass} onChange={massC} />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  Kg
                </StaticButton>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>
                  Temperature<sup>initial</sup>
                </StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  value={temperature1}
                  onChange={temperature1C}
                />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  °C
                </StaticButton>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>
                  Temperature<sub>final</sub>
                </StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  value={temperature2}
                  onChange={temperature2C}
                />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  °C
                </StaticButton>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Specific Heat</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  value={specificHeat}
                  onChange={specificHeatC}
                />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  J/Kg.C
                </StaticButton>
              </Control>
            </Field>

            <p>{myError}</p>
            <FlexDiv display="flex" className="mb-3">
              <Button
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                value="Calculate"
                onClick={calculate}
              >
                Calculate
              </Button>
              <Button
                borderColor="#ea4336"
                color="#ea4336"
                hoverBorderColor="#ea4336"
                hoverBackgroundColor="#ea4336"
                hoverColor="white"
                value="Reset"
                onClick={reset}
              >
                Reset
              </Button>
            </FlexDiv>
          </Column>
          <Column>
            <FlexDiv maxWidth="300px" margin="auto">
              <SmallImg filename="heat.png" alt="specific heat calculator" />
            </FlexDiv>
          </Column>
        </Columns>
        <br />
        <h3>Specific Heat</h3>
        <p>
          Heat is the amount of energy streaming from one body of matter to
          another automatically due to their temperature variation. The specific
          heat capacity (specific heat), is the heat capacity per unit mass of
          material.
        </p>
        <br /> <h3>Specific Heat Formula</h3>
        <p>
          <strong>
            {" "}
            Q = total energy,m = mass, c = heat. <br />
            c = Q / m x change in Temperature <br />Q = c x m x change in
            Temperature
          </strong>
          <br />
          First, discover the total amount of energy being placed into the
          system. measure the mass of the object you are testing/calculating,
          measure the variation in temperature of the object. When you get into
          ambient lost heat, these equations of systems get much more confused,
          but for a simple experiment just measure the temperature of the object
          is enough, finally calculated.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default SpecificHeatCalculator
